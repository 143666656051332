<template>
  <div class="ufaceGroupDevicePage">
    <el-container>
      <el-row>
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form :inline="true">
            <el-input
              v-model="keyword"
              placeholder="请输入关键字查询"
              class="input-with-select"
              style="width: 300px; margin-right: 10px"
              size="medium"
            >
              <template #prepend>
                <el-select
                  v-model="checkInput"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                  style="width: 120px"
                >
                  <el-option
                    v-for="(item, i) in dataTableSearch"
                    :key="i"
                    :label="item.label"
                    :value="item.attr"
                  ></el-option>
                </el-select>
              </template>
            </el-input>
            <slot name="searchSlot"></slot>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                size="medium"
                @click="SearchPage()"
                >查询</el-button
              >
              <el-button
                type="danger"
                v-has="'FakeDelete'"
                icon="el-icon-delete"
                size="medium"
                @click="deleteUfaceGroupPerson()"
                >人员销权</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-popover placement="bottom" :width="400" trigger="click">
                <template #reference>
                  <el-button size="medium"
                    ><i class="el-icon-arrow-down el-icon-menu" /> 列筛选</el-button
                  >
                </template>
                <el-checkbox-group v-model="dataTableSearchList">
                  <div v-for="(item, i) in dataTableList" :key="i">
                    <el-checkbox
                      v-if="item.visible != false"
                      :label="item"
                      checked
                      style="display: block; margin: 10px"
                      @change="filterFunHandle('filter', item.attr, i)"
                    >
                      {{ item.label }}</el-checkbox
                    >
                    <el-checkbox
                      v-else
                      :label="item"
                      style="display: block; margin: 10px"
                      @change="filterFunHandle('filter', item.attr, i)"
                    >
                      {{ item.label }}</el-checkbox
                    >
                  </div>
                </el-checkbox-group>
                <el-button size="small" type="text" @click="filterFunHandle('allchecked')"
                  >全选</el-button
                >
                <el-button size="small" type="text" @click="filterFunHandle('cancel')"
                  >取消全选</el-button
                >
              </el-popover>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-table
            size="small"
            border
            ref="multipleTable"
            :data="dataTable"
            style="width: 99%"
            max-height="400"
            :height="tableHeight"
            :fit="true"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              fixed="left"
              header-align="center"
              align="center"
            ></el-table-column>
            <el-table-column
              label="操作"
              fixed="right"
              header-align="center"
              align="center"
              width="100"
            >
              <template #default="scope">
                <el-popconfirm
                  confirmButtonText="确定"
                  cancelButtonText="取消"
                  icon="el-icon-info"
                  iconColor="red"
                  title="确定销权吗？"
                  @confirm="ufaceGroupPersonDelete(scope.row.Id)"
                >
                  <template #reference>
                    <el-button type="danger" size="mini">销权</el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
            <el-table-column
              v-for="(col, index) in dataTableSearchList"
              :key="index"
              sortable
              header-align="center"
              align="center"
              :prop="col.attr"
              :label="col.label"
            >
              <template #default="scope">
                <el-image
                  v-if="col.type == 'img'"
                  :src="scope.row[col.attr]"
                  :preview-src-list="[scope.row[col.attr]]"
                  fit="fill"
                  style="width: 40px; height: 40px"
                ></el-image>
                <el-tag size="medium" v-else-if="col.type == 'enum'">
                  {{ col.formatters(scope.row[col.attr]) }}
                </el-tag>
                <label v-else>{{ scope.row[col.attr] }}</label>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <paging
            :currentPage="currentPage"
            :pagesize="pageSize"
            :total="total"
            @sizeChange="handleSizeChange"
            @currentChange="handleCurrentChange"
            :pagesizes="pageSizes"
          />
        </el-col>
      </el-row>
    </el-container>
  </div>
</template>
<script>
let _this;
export default {
  name: "ufaceGroupPerson",
  props: {
    groupGuid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      total: 0, //数据总条数
      pageSize: 10, //当前显示数据数量
      pageSizes: [10, 20, 50, 100, 200, 500, 1000],
      currentPage: 1, //分页当前页
      keyword: "", //搜索输入关键字
      checkInput: "", //选择所需要查询的内容
      dataConfig: [
        {
          label: "Id",
          attr: "Id",
          isInput: false,
          isDisplay: false,
        },
        {
          label: "授权组名称",
          attr: "GroupName",
        },
        {
          label: "人员姓名",
          attr: "PersonName",
          isSearch: true,
        },
        {
          label: "人员类型",
          attr: "PersonType",
          type: "enum",
          formatters: function (val) {
            var result;
            switch (val) {
              case 0:
                result = "学生";
                break;
              case 1:
                result = "教职工";
                break;
              default:
                result = "未知";
                break;
            }
            return result;
          },
        },
        {
          label: "标签",
          attr: "Tag",
          isSearch: true,
        },
        {
          label: "备注",
          attr: "RemarkInfo",
          visible: false,
        },
         {
          label: "人员GUID",
          attr: "PersonGuid",
          visible: false,
        },
        {
          label: "授权时间",
          attr: "CreatedTime",
          visible: false,
        },
        {
          label: "更新时间",
          attr: "UpdatedTime",
          visible: false,
        },
      ],
      dataTableSearch: [], //搜索信息
      dataTableList: [], //显示列信息
      dataTableSearchList: [], //列筛选信息
      dataTable: [], //显示数据
      multipleSelection: [], //选中数据
      tableHeight: 200, //table高度
    };
  },
  mounted() {
    _this = this;
    _this.$nextTick(function () {
      // 仅在整个视图都被渲染之后才会运行的代码
      //设置table位置
      let heightStyle = window.innerHeight - 230;
      _this.tableHeight = heightStyle;

      //窗口大小改变时，table 高度设置
      window.onresize = () => {
        return (() => {
          let heightStyle = window.innerHeight - 230;
          _this.tableHeight = heightStyle;
        })();
      };
    });
    _this.SearchPage();
    _this.dataTableSearch = _this.dataConfig.filter((it) => it.isSearch == true); //可搜索字段
    _this.dataTableList = _this.dataConfig.filter((it) => it.isDisplay != false); //可显示列
  },
  methods: {
    //页面搜索查询
    SearchPage() {
      let json = {
        searchValue: "",
        pageNo: _this.currentPage,
        pageSize: _this.pageSize,
        searchBeginTime: _this.searchStartTime,
        searchEndTime: _this.searchEndTime,
        sortField: "",
        sortOrder: "",
        descStr: "",
        groupGuid: _this.$props.groupGuid,
      };
      if (_this.checkInput.length > 0 && _this.keyword != "") {
        var searchParameters = [],
          input = _this.keyword;
        for (let index = 0; index < _this.checkInput.length; index++) {
          const element = _this.checkInput[index];
          var searchParametersItem = {
            field: element,
            op: 8,
            value: input,
            orGroup: "",
          };
          searchParameters.push(searchParametersItem);
        }
        json.searchParameters = searchParameters;
      }
      _this.$API.UfaceGroupPerson.QueryByPage.post(json).then((res) => {
        if (res) {
          _this.total = res.TotalRows;
          _this.pagesize = res.PageSize;
          _this.currentPage = res.PageNo;
          _this.dataTable = res.Rows;
          console.log(_this.dataTable);
        }
      });
    },
    handleSizeChange(val) {
      _this.pageSize = val;
      _this.SearchPage();
    },
    handleCurrentChange(val) {
      _this.currentPage = val;
      _this.SearchPage();
    },
    handleSelectionChange(val) {
      _this.multipleSelection = val;
    },
    // 列筛选
    filterFunHandle(type, currentItem, index) {
      if (type === "allchecked") {
        // 全选
        _this.dataTableSearchList = _this.dataConfig.filter(
          (it) => it.isDisplay != false
        );
      } else if (type === "cancel") {
        // 取消全选
        _this.dataTableSearchList = []; // 复选框置为空，全部不选择
      }
    },
    deleteUfaceGroupPerson() {
      if (!_this.multipleSelection || _this.multipleSelection.length <= 0) {
        _this.$message.warning("请选择需要销权的人员！");
        return;
      }
      var ids = new Array();
      var msgStr = "";
      _this.multipleSelection.forEach((it) => {
        ids.push(it.Id);
        msgStr += `【${it.PersonName}】`;
      });
      _this
        .$confirm(`确认销除${msgStr}等${_this.multipleSelection.length}个人员的授权信息吗？`)
        .then(() => {
          _this.$API.UfaceGroupPerson.FakeDelete.delete(ids).then((res) => {
            if (res.Success) {
              _this.$message.success(res.Message);
              _this.SearchPage();
            }
          });
        })
        .catch(() => {});
    },
    ufaceGroupPersonDelete(id) {
      _this.$API.UfaceGroupPerson.FakeDelete.delete([id]).then((res) => {
        if (res.Success) {
          _this.$message.success(res.Message);
          _this.SearchPage();
        }
      });
    },
  },
};
</script>
<style scoped>
.ufaceGroupDevicePage {
  padding:10px;
  min-height: 500px;
  width: 95%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  width: auto;
}
</style>
