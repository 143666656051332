<template>
  <el-form :model="groupForm" ref="groupForm" label-width="100px">
    <div v-for="(item, index) in groupDataForm" :key="index">
      <el-form-item
        :label="item.label"
        v-if="item.isInput != false"
        :rules="item.rules"
        :prop="item.attr"
      >
        <el-input
          v-if="item.type == 'textarea'"
          type="textarea"
          v-model="groupForm[item.attr]"
          :placeholder="item.placeholder"
        ></el-input>
        <el-select
          v-else-if="item.type == 'enum'"
          v-model="groupForm[item.attr]"
          :placeholder="item.placeholder"
          filterable
        >
          <el-option
            v-for="ite in selectConfigData"
            :key="ite.value"
            :label="ite.title"
            :value="ite.value"
          >
          </el-option>
        </el-select>
        <el-input
          v-else
          v-model="groupForm[item.attr]"
          :placeholder="item.placeholder"
        ></el-input>
      </el-form-item>
    </div>

    <el-form-item>
      <el-button type="primary" @click="submitForm('groupForm')">保存</el-button>
      <el-button @click="resetForm('groupForm')">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "saveUfaceGroup",
  props: {
    groupId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectConfigData: [],
      groupForm: {
        Id: 0,
        AppID: "",
        GroupName: "",
        RemarkInfo: "",
      },
      groupDataForm: [
        {
          label: "Id",
          attr: "Id",
          isInput: false,
        },
        {
          label: "配置",
          attr: "AppID",
          placeholder: "请选择配置",
          rules: [
            {
              required: true,
              message: "请选择配置",
              trigger: "change",
            },
          ],
          type: "enum",
        },
        {
          label: "授权组名称",
          attr: "GroupName",
          placeholder: "请输入授权组名称",
          rules: [
            {
              required: true,
              trigger: "blur",
              message: "请输入授权组名称",
            },
          ],
        },
        {
          label: "备注",
          attr: "RemarkInfo",
          placeholder: "请输入备注（长度不大于100个字符）",
          rules: [
            {
              min: 0,
              max: 100,
              message: "长度不大于100个字符",
              trigger: "blur",
            },
          ],
          type: "textarea",
        },
      ],
    };
  },
  mounted() {
    var that = this;
    that.$nextTick(function () {
      that.initData();
    });
  },
  methods: {
    initData() {
      var that = this;
      var data = [];
      this.$API.UfaceConfig.GetUfaceConfigList.get().then((res) => {
        if (res) {
          res.forEach((it) => {
            data.push({ value: it.AppID, title: it.ConfigName });
          });
          that.selectConfigData = data;
          if (that.$props.groupId != 0) {
            that.$API.UfaceGroup.QueryById.get(that.$props.groupId).then((res1) => {
              that.groupForm = res1;
            });
          }
        }
      });
    },
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.$API.UfaceGroup.AddOrUpdate.post(that.groupForm).then((res) => {
            if (res.Success) {
              that.$message.success(res.Message);
              that.$emit("operationSuccess");
            } else {
              that.$message.error(res.Message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style></style>
